@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-action {
    @apply cursor-pointer flex flex-row items-center rounded-lg sm:p-4 p-4 m-2 font-bold;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,900;1,200;1,300;1,400&display=swap');

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  font-family: 'Nunito', sans-serif;
}

body, html{
  padding: 0px;
  margin: 0px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

  
table td{
  border:1px solid rgb(226, 232, 240);
}

.bottom-shadow{
  -moz-box-shadow: inset 0 -10px 20px -10px rgb(14 165 233);
  -webkit-box-shadow: inset 0 -10px 20px -10px rgb(14 165 233);
  box-shadow: inset 0 -40px 40px -40px rgb(14 165 233);
}

.scroll-height {
  height: 75vh;
}

.scroll-height-showcase {
  height: 100%;
}

#root {
  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 640px) {
  .showcase{
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
    background-color: white;
    position: relative;
    right: 0;
    top: 0;
    box-shadow: 5px 5px 25px #00000066;
  }

  .hide-sm{
    display: none;
  }

  .hide-lg{
    display: inline-block;
  }
}


@media only screen and (min-width: 640px) {
  .showcase{
    width: 380px;
    height: 640px;
    border-radius: 5px;
    background-color: white;
    position: fixed;
    right: 1rem;
    top: calc(50% - 320px);
    box-shadow: 5px 5px 25px #00000066;
  }
  .hide-lg{
    display: none;
  }
}

@media only screen and (min-width: 1000px) {
  .showcase{
    width: 380px;
    height: 640px;
    border-radius: 5px;
    background-color: white;
    position: fixed;
    right: calc(25% - 80px);
    top: calc(50% - 320px);
    box-shadow: 5px 5px 25px #00000066;
  }

  .hide-sm{
    display: inline-block;
  }
}



.p{
  padding: env(safe-area-inset-top) 0px 0px 0px;
}

.background_orange{
  background-image: url(/src/svg/background.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}


html {
  height: -webkit-fill-available;
}

  
.no-outline:focus {
    outline: none;
  }

.pass-event {
  pointer-events: none;
}

.phone-size {
  width: 450px;
  height: 800px;
}

.min-phone-width{
  min-width: 450px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.phone-size-overlay {
  width: 450px;
  height: 400px;
  overflow-y: auto;
}

.sb.special{
  background: rgb(253 230 138);
  color: rgb(180 83 9);
  font-size: 2rem;
  padding: 20px;
}

.pattern{
  background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%233b83f6' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
.bg-whatever{
  background-color: #23a9cbe1;
}

@media (min-width: 768px) {
  .sb-annotation-left {
    font-size: 1.4rem;
  }
  
  .sb-annotation-right {
    font-size: 1.4rem;
  }
}

.sb.special1{
  background: rgb(253 230 138);
  color: rgb(180 83 9);
  font-size: 1.3rem;
  padding: 15px;
}



.sb {
  background: rgb(253 230 138);
  font-family: 'Nunito';
  padding: 10px;
  margin: 10px 20px 10px 20px;
  font-weight: 600;
  color: rgb(180 83 9);
  position: relative;
  display: inline;
  border-radius: 10px
}

.sb-annotation-left-small {
  margin: 10px 20px -5px 20px;
  font-weight: 900;
  font-family: "Nunito";
  position: relative;
  display: inline;
}

.sb-annotation-right-small {
  margin: 10px 20px -5px 20px;
  font-weight: 900;
  font-family: "Nunito";
  position: relative;
  text-align: right;
  display: inline;
}

.sb-annotation-left {
  margin: 10px 20px -5px 20px;
  font-weight: 900;
  font-family: "Nunito";
  position: relative;
  display: inline;
}

.sb-annotation-right {
  margin: 10px 20px -5px 20px;
  font-weight: 900;
  font-family: "Nunito";
  position: relative;
  text-align: right;
  display: inline;
}

.sp-max-w {
  max-width: 70%;
}

.sp-max-w-mobile {
  max-width: 100%;
}

.sb-info {
  
  border-width: 4px;
  border-color: rgb(226 232 240);;
  border-style: dotted;
  padding: 10px;
  margin: 10px 20px 10px 20px;
  font-weight: 900;
  color: rgb(148 163 184);
  font-family: 'Nunito';
  position: relative;
  display: inline;
}

.sb-block {
  background: rgb(125 211 252);
  color: rgb(3 105 161);
  padding: 10px;
  margin: 10px 20px 10px 20px;
  font-weight: 900;
  font-family: 'Nunito';
  position: relative;
}

.width-word-chips{
  width: calc(100vw - 15rem);
}

.width-word-chips-levels{
  width: calc(100vw - 30rem);
}

.width-word-chips-array{
  width: calc(100vw - 33rem);
}

.width-word-chips-s{
  width: calc(100vw - 15rem);
}

.width-word-chips-levels-s{
  width: calc(100vw - 45rem);
}

.width-word-chips-array-s{
  width: calc(100vw - 48rem);
}


.sb-right:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid rgb(253 230 138);
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(253 230 138);
  border-bottom: 5px solid transparent;
  right: -10px;
  top: 20px;
}

.sb-input {
  background-color: #cbd5e100;
  width: 50vw;
}

.sb-input-right {
  text-align: right;
}

.sb-highlight{
  border: 2px solid rgb(29 78 216);
}

.bg-item{
  transform: rotate(-30deg) translate(-20%,-50%);
  z-index: -1;
}

.blue-corner {
  content: "";
  width: 0px;
  height: 0px;
  border-right: 10px solid transparent;
  border-left: 10px solid rgb(14 165 233);
  border-top: 10px solid rgb(14 165 233);
  border-bottom: 10px solid transparent;
}

.sb-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid rgb(253 230 138);
  border-top: 5px solid rgb(253 230 138);
  border-bottom: 5px solid transparent;
  left: -10px;
  top: 20px;
}

.sb-right1:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid rgb(253 230 138);
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(253 230 138);
  border-bottom: 5px solid transparent;
  right: -10px;
  top: 20px;
}

.sb-left1:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid rgb(253 230 138);
  border-top: 5px solid rgb(253 230 138);
  border-bottom: 5px solid transparent;
  left: -10px;
  top: 20px;
}

.sb-right2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid  rgb(253 230 138);
  border-right: 5px solid transparent;
  border-top: 5px solid  rgb(253 230 138);
  border-bottom: 5px solid transparent;
  right: -10px;
  top: 20px;
}

.sb-left2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid  rgb(253 230 138);
  border-top: 5px solid  rgb(253 230 138);
  border-bottom: 5px solid transparent;
  left: -10px;
  top: 20px;
}


.item-enter {
  opacity: 0;
  transform: translateX(-1em);
  
}
.item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms ease-in;
}
.item-exit {
  font-size: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
    /* fade out, then shrink */
  transition: opacity .25s,
                font-size .5s .25s,
                margin .5s .25s,
                padding .5s .25s;
}
.item-exit-active {
  transition: font-size .25s,
                margin .25s,
                padding .25s,
                opacity .5s .25s;
  
}

.shine {
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.shine:before {
  content: "";
  position: absolute;
  top: -20%;
  right: 10%;
  width: 50%;
  height: 150%;
  opacity: 1;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.18);
  background: linear-gradient( to right, rgba(255, 255, 255, 0.248) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
  .container-width{
    width: 100%;
  }

  .sb.special{
    font-size: 1.7rem;
    padding: 10px;
  }
  
  .sb.special1{
    font-size: 1.3rem;
    padding: 10px;
  }
}

.thumbUl {
	position: relative;
	list-style: none;
	margin-left: 0;
	padding-left: 1.2em;
}
.thumbUl li:before {
	content: "👍";
	position: absolute;
	left: 0;
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
  .container-width{
    width: 100%;
  }
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 700px) {
  .hide_story_list{
    display: none;
  }
}
 
/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  .container-width{
    margin-left: 5vw;
    margin-right: 5vw;
    width: 100%;
  }
}
 
/* Big screen devices (889px and above) */
@media only screen and (min-width: 984px) {
  .container-width{
    margin-left: 10vw;
    margin-right: 10vw;
    width: 90%;
  }
}
 
/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1240px) {
  .container-width{
    margin-left: 15vw;
    margin-right: 15vw;
    width: 85%;
  }
}

.ws-container{
}

.word-stat-item{
  box-sizing: border-box;
  height: 4px;
  overflow: hidden;
}

.ws-words{
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  white-space: no-wrap;
}

.ws-container:hover .word-stat-item{
  height: 25px;
}



.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(190, 202, 227);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.boring-bg{
  background-repeat: no-repeat;
  background-position: bottom right;
 
}

.cShadow{
  box-shadow: 10px 10px 26px -11px rgba(0,0,0,0.23);
  -webkit-box-shadow: 10px 10px 26px -11px rgba(0,0,0,0.23);
  -moz-box-shadow: 10px 10px 26px -11px rgba(0,0,0,0.23);
}

.blob {
	transform: scale(0.98);
}

.pl{
  color: black;
}

